import React, {Component} from 'react';

class LoadingStartComponent extends Component {
    render() {
        return (
            <div className="d-flex flex-column align-items-center">
                <div  style={{marginTop: '20px'}}>
                    Loading
                </div>
                <div style={{marginTop: '20px'}}>
                    <h5>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="64"
                            height="64"
                            version="1"
                            viewBox="0 0 128 128">
                            <rect width="100%" height="100%" fill="#FFF" fillOpacity="0"/>
                            <g>
                                <path
                                    fill="#0178d6"
                                    d="M78.75 16.18V1.56a64.1 64.1 0 0147.7 47.7H111.8a49.98 49.98 0 00-33.07-33.08zM16.43 49.25H1.8a64.1 64.1 0 0147.7-47.7V16.2a49.98 49.98 0 00-33.07 33.07zm33.07 62.32v14.62A64.1 64.1 0 011.8 78.5h14.63a49.98 49.98 0 0033.07 33.07zm62.32-33.07h14.62a64.1 64.1 0 01-47.7 47.7v-14.63a49.98 49.98 0 0033.08-33.07z"/>
                                <animateTransform
                                    attributeName="transform"
                                    dur="400ms"
                                    from="0 64 64"
                                    repeatCount="indefinite"
                                    to="-90 64 64"
                                    type="rotate"/>
                            </g>
                        </svg>
                    </h5>
                </div>
            </div>
        );
    }
}

export default LoadingStartComponent;
