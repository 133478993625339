import {DOTS, usePagination} from './usePagionation';
import './Pagination.scss';
import React from "react";
import classnames from 'classnames';

interface PaginationProps {
    onPageChange: any,
    totalCount: number,
    siblingCount: number,
    currentPage: number,
    pageSize: number,
    className: string
}

export const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination(
        totalCount,
        pageSize,
        siblingCount,
        currentPage,
    );

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <React.Fragment>
            <ul className={classnames('pagination-container', {[className]: className})}>
                {/* Left navigation arrow */}
                <li className={classnames('pagination-item', {disabled: currentPage === 1})} onClick={onPrevious}>
                    <div className="arrow left"/>
                </li>
                {
                    paginationRange.map((pageNumber: any) => {

                        // If the pageItem is a DOT, render the DOTS unicode character
                        if (pageNumber === DOTS) {
                            return <li className="pagination-item dots">&#8230;</li>
                        }
                        // Render our Page Pills
                        return (
                            <li key={`pageNumber-${pageNumber}`} className={classnames('pagination-item', {selected: pageNumber === currentPage})}
                                onClick={() => onPageChange(pageNumber)}>
                                {pageNumber}
                            </li>
                        );
                    })
                }
                {/*  Right Navigation arrow */}
                <li className={classnames('pagination-item', {disabled: currentPage === lastPage})}
                    onClick={onNext}>
                    <div className="arrow right"/>
                </li>
            </ul>
        </React.Fragment>
    )
};
