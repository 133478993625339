import React from 'react';
import './App.css';
import {HomePage} from "./pages/HomePage/HomePage";

interface State {}

export interface Props {}

export function setPageTitle(title: string) {
    if (document.title !== title) {
        // Reset the page to the top
        window.scrollTo(0, 0)
        document.title = title;
    }
}

export class App extends React.Component<Props, State> {

    render() {
        return (
            <HomePage/>
        );
    }
}

export default App;