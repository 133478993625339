import {Request, requestMethods} from './request'
import configReader from "../../helpers/config";
import {
    HTTP_GET,
    HTTP_POST,
    HTTP_GET_ACCESSES
} from "./reqeuest.constants";

const NO_BODY = null;
const config = configReader();

export const requestMap = {
    getAccesses(csrfToken: string): Promise<any> {
        return Request(requestMethods.GET, HTTP_GET_ACCESSES, `${config.apiUrl}/myaccess?action=getMyAccesses`, NO_BODY, {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'X-CSRF-Token':csrfToken,
            }
        })
    },

    getData(): Promise<any> {
        return Request(requestMethods.GET, HTTP_GET, `${config.apiUrl}/myaccess/data`, NO_BODY, {
            credentials: 'include',
            mode: 'cors'
        })
    },

    disableAccessClient(app:string, client: string): Promise<any> {
        return Request(requestMethods.POST, HTTP_POST, `${config.apiUrl}/myaccess?action=hideAccess`, {"application": app, "client": client}, {
            credentials: 'include',
            mode: 'cors'
        })
    },

    enableAccessClient(app: string, client: string): Promise<any> {
        return Request(requestMethods.POST, HTTP_POST, `${config.apiUrl}/myaccess?action=unhideAccess`, {"application": app, "client": client}, {
            credentials: 'include',
            mode: 'cors'
        })
    },

    /*
    Generic GET request for the clients API that takes the following parameters:
        path: string = the path of the url
        queryStringParameters: Record<string, string> = all the query parameters that have to be added to the url
     */
    getRequest(path: string, queryStringParameters: Record<string, string>): Promise<any> {
        // Creating the query string parameters for the url
        let queryString = "";
        for (let key in queryStringParameters) {
            if (queryString !== "") {
                queryString += "&"
            }
            queryString += key + "=" + queryStringParameters[key]
        }

        return Request(requestMethods.GET, HTTP_GET, `${config.apiUrl}/${path}?${queryString}`, NO_BODY, {
            credentials: 'include',
            mode: 'cors'
            // headers: {
            //     ...headerTokenBearer(),
            // }
        })
    },
};