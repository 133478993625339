import React from "react";
import './line.css';

export interface LineInputProps {
    title?: string
    hidden?: boolean
}

export const LineComponent: React.FC<LineInputProps> = (props: LineInputProps) => {
    return (
        <div className={"line"} hidden={props.hidden}>
          <span className={"lineText"}>
            {props.title ? props.title : ""}
          </span>
        </div>
    );
};
