import React, {useEffect, useMemo, useState} from "react";
import {setPageTitle} from "../../App";
import UserInfo from "../UserInfo/UserInfo";
import LoadingStartComponent from "../../shared/loading/loadingStartComponent";
import './HomePage.scss';
import {requestMap} from "../../shared/service/request/requestMap";
import {LineComponent} from "../../shared/input/line/line";
import {StringInput} from "../../shared/input/string-input/string-input";
import {Pagination} from "../Pagination/Pagination";
import {Button, Col, ListGroup, Row, Accordion, Card} from "react-bootstrap";
import {ToggleInput} from "../../shared/input/toggle/toggle-input.component";

export interface HomePageProps {
}

export const HomePage: React.FC<HomePageProps> = (props: HomePageProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    // Original Access Data (Unchanged after loading from backend)
    const [access, setAccess] = useState<any[]>([]);
    // Filtered data based on set filters
    const [filteredAccess, setFilteredAccess] = useState<any[]>([]);
    // Subselection of filtered data to display in paginated sections
    const [currentPage, setCurrentPage] = useState<number>(1);
    const pageSize: number = 10;
    const [reset, setReset] = useState<string>(Date.now().toString());

    const [filter, setFilter] = useState<string>("");
    const [awsOnly, setAwsOnly] = useState<boolean>(false);
    const [bgpOnly, setBgpOnly] = useState<boolean>(false);
    const [productionOnly, setProductionOnly] = useState<boolean>(false);
    const [includeCodeCommit, setIncludeCodeCommit] = useState<boolean>(false);
    const [codeCommitOnly, setCodeCommitOnly] = useState<boolean>(false);
    const [includeHidden, setIncludeHidden] = useState<boolean>(false);

    const currentPageData = useMemo(() => {
        setReset(Date.now().toString());
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return filteredAccess.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, filteredAccess]);

    const fetchAccess = async (withTimeout: boolean) => {
        function timeout(delay: number) {
            return new Promise( res => setTimeout(res, delay) );
        }

        if (withTimeout) {
            await timeout(2000); //for 2 seconds delay
        }

        // Set the module to loading while fetching the data
        setLoading(true);

        const csrfTokenValue = await requestMap.getRequest("myaccess/data", {"attribute": "csrftoken"});

        // Get Access Info from FE Lambda by calling it with user token
        let data = await requestMap.getAccesses(csrfTokenValue["csrf_token"]);
        // Structure the data per client --> data per application (in a map for easy processing)
        let tempAccessRecord: Record<string, any[]> = {}
        await (data).forEach((d: any) => {
            if (tempAccessRecord[d["application"] as string] === undefined) {
                tempAccessRecord[d["application"] as string] = [d]
            } else {
                tempAccessRecord[d["application"] as string].push(d)
            }
        })
        // Convert the map to an array for pagination
        let tempAccess: any[] = []
        Object.keys(tempAccessRecord).forEach((key: string) => {
            tempAccess.push({"application": key, "clients": tempAccessRecord[key]})
        })
        setAccess(tempAccess)
        setFilteredAccess(tempAccess)
        await setLoading(false);
    };

    // Get accesses
        useEffect(() => {
            fetchAccess(false)
            setPageTitle("My Access")
        }, []);

        // Apply the filters
        useEffect(() => {
            let visibleAccesses: any[] = []
            if (includeHidden) {
                visibleAccesses = access
            } else {
                access.forEach((a: any) => {
                    // Check every client on the app and filter it if it's not production
                    var tempClients: any[] = []
                    // Dereference the application to prevent overwriting the original access data
                    var tempApplication = {...a}
                    tempApplication.clients.forEach((c: any) => {
                        if (c.visible) {
                            tempClients.push(c)
                        }
                    })
                    if (tempClients.length !== 0) {
                        tempApplication.clients = tempClients
                        visibleAccesses.push(tempApplication)
                    }
                })
            }

       // Filter codecommit clients
       let initialAccess: any[] = []
       if (includeCodeCommit === false) {
           // Filter codecommit clients from initial response - default behaviour
           visibleAccesses.forEach((a: any) => {
                       // Check every client on the app and filter it if it's not production
                       var tempClients: any[] = []
                       // Dereference the application to prevent overwriting the original access data
                       var tempApplication = {...a}
                       tempApplication.clients.forEach((c: any) => {
                           if (!c.client.toLowerCase().includes(":codecommit:")) {
                               tempClients.push(c)
                           }
                       })
                       if (tempClients.length !== 0) {
                           tempApplication.clients = tempClients
                           initialAccess.push(tempApplication)
                       }
           })
           } else {
           if (codeCommitOnly === true) {
               // Filter only codecommit clients
               visibleAccesses.forEach((a: any) => {
                   // Check every client on the app and filter it if it's not production
                   var tempClients: any[] = []
                   // Dereference the application to prevent overwriting the original access data
                   var tempApplication = {...a}
                   tempApplication.clients.forEach((c: any) => {
                       if (c.client.toLowerCase().includes(":codecommit:")) {
                           tempClients.push(c)
                       }
                   })
                   if (tempClients.length !== 0) {
                       tempApplication.clients = tempClients
                       initialAccess.push(tempApplication)
                   }
               })
           } else {
               // include codecommit true - all clients are in scope
               initialAccess = visibleAccesses
           }
       }

       // This is the search filter that gets triggered every time a filter changes state or the
       // original access data changes
        let tempAccess: any[] = []
        initialAccess.forEach((a: any) => {
            // Check for application name
            if (a.application.toLowerCase().includes(filter.toLowerCase()) &&
                // AND whether AWS only toggle is selected (application name includes 'aws')
                (!awsOnly || a.application.toLowerCase().includes("aws"))) {
                // Check if only production clients are to be displayed
                if (!productionOnly && !bgpOnly) {
                    tempAccess.push(a)
                } else {
                    // Check every client on the app and filter it if it's not production
                    var tempClients: any[] = []
                    // Dereference the application to prevent overwriting the original access data
                    var tempApplication = {...a}
                    tempApplication.clients.forEach((c: any) => {
                        if ((!productionOnly || c.production_workload) &&
                            (!bgpOnly || c.break_glass_procedure_client)) {
                            tempClients.push(c)
                        }
                    })
                    if (tempClients.length !== 0) {
                        tempApplication.clients = tempClients
                        tempAccess.push(tempApplication)
                    }
                }
            }
        })
        setFilteredAccess(tempAccess);
        // TODO: Find a better way to reset the unfold on the access list
        setReset(Date.now().toString());
        }, [access, filter, awsOnly, bgpOnly, productionOnly, includeCodeCommit, codeCommitOnly, includeHidden]);


    const updateFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    }

    const updateAwsOnly = () => {
        setAwsOnly(!awsOnly);
    }

    const updateBgpOnly = () => {
        setBgpOnly(!bgpOnly);
    }

    const updateProductionOnly = () => {
        setProductionOnly(!productionOnly);
    }

    const updateIncludeCodeCommit = () => {
        setIncludeCodeCommit(!includeCodeCommit);
    }

    const updateCodeCommitOnly = () => {
        setCodeCommitOnly(!codeCommitOnly);
    }

    const updateIncludeHidden = () => {
        setIncludeHidden(!includeHidden);
    }

    const handleChange = () => {
        // do nothing
    }

    const disableAllAccesses = () => {
        setLoading(true);
        console.log("access")
        console.log(access)
        var listOfAccesses: string = ""
        access.forEach((a: any) => {
            var tempApplication = {...a}
            tempApplication.clients.forEach((c: any) => {
                listOfAccesses = listOfAccesses + ";"+a.application+"+"+c.client
            })
        })
        requestMap.disableAccessClient("all", listOfAccesses.substring(1,listOfAccesses.length))
        fetchAccess(true)
    }

    const enableAllAccesses = () => {
        setLoading(true);
        requestMap.enableAccessClient("all", "all")
        fetchAccess(true)
    }


    return (
        <React.Fragment>
            {loading &&
                <LoadingStartComponent/>
            }
            {!loading &&

                <div className="App">
                    <Row>
                        <Col xl>
                            <UserInfo/>
                        </Col>
                    </Row>

                    <Row>
                        {/*Selection Sliders*/}
                        <ListGroup style={{marginLeft: "50px", marginTop: "50px"}}>
                            <br></br>
                            <Button variant="primary" onClick={() => fetchAccess(false)}>
                                Reload Accesses
                            </Button>
                            <br></br>
                            <ToggleInput id="aws_toggle" value={awsOnly} labelLength={8} handleChange={updateAwsOnly}
                                         label={"AWS Only"}/>
                            <ToggleInput id="production toggle" value={productionOnly} labelLength={8}
                                         handleChange={updateProductionOnly} label={"Production Only"}/>
                            <ToggleInput id="bgp toggle" value={bgpOnly} labelLength={8}
                                         handleChange={updateBgpOnly} label={"BGP Only"}/>
                            <ToggleInput id="include codecommit toggle" value={includeCodeCommit} labelLength={8}
                                         handleChange={updateIncludeCodeCommit} label={"Include CodeCommit"}/>
                            <ToggleInput id="only codecommit toggle" hidden={!includeCodeCommit} value={codeCommitOnly} labelLength={8}
                                         handleChange={updateCodeCommitOnly} label={"CodeCommit Only"}/>

                            <Accordion defaultActiveKey="0" style={{width: "100%"}}>
                                <Card>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Button as={Button} variant="link" style={{width: "100%"}}>
                                            <Card.Header>Manage Hidden Entries</Card.Header>
                                        </Accordion.Button>
                                        <Accordion.Body>
                                            <Card.Body>
                                                <ToggleInput id="hidden_toggle" value={includeHidden} labelLength={7} handleChange={updateIncludeHidden}
                                                             label={"Show Hidden"}/>
                                                <LineComponent/>
                                                <br></br>
                                                <Button variant="danger" onClick={() => disableAllAccesses()}>
                                                    Hide All Accesses
                                                </Button>
                                                <br></br>
                                                <br></br>
                                                <Button variant="primary" onClick={() => enableAllAccesses()}>
                                                    Unhide All Accesses
                                                </Button>
                                                <br></br>
                                                <br></br>
                                                <div style={{textAlign: "left", width: "180px"}}>My Access will reload automatically after clicking one of the buttons for enabling or disabling all accesses</div>
                                            </Card.Body>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Card>
                            </Accordion>

                            <hr></hr>
                            <LineComponent/>
                            <hr></hr>

                            <a href={"https://sso.federation.nnip.com/auth/saml2/idp?initiatedrplogin=urn:aws:accounts:aws:console-access"} target="_blank" rel="noopener noreferrer">
                                <Button variant="outline-secondary">
                                    <img width="45" height="45" src={require("../../images/my-access-aws.png")} alt='myAccessAWSlink'/>
                                    <>  </>AWS Console
                                </Button>
                            </a>
                            <hr></hr>
                            <a href={"https://patronus.nnip.com"} target="_blank" rel="noopener noreferrer">
                                <Button variant="outline-secondary">
                                    <img width="78" height="45" src={require("../../images/patronus-logo.png")} alt='myAccessPatronuslink'/>
                                    <> </>Patronus
                                </Button>
                            </a>

                        </ListGroup>
                        <Col xl>
                            <br></br>
                            {/*Search Filter*/}
                            <StringInput placeholder={"Search application..."} className={"filterBox"} value={filter}
                                         handleChange={updateFilter}/>
                            {/*Pagination Bar*/}
                            <Pagination
                                siblingCount={1}
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={filteredAccess.length}
                                pageSize={pageSize}
                                onPageChange={(page: number) => setCurrentPage(page)}
                            />
                            {/*Access List*/}
                            {currentPageData.map((a: any) => {
                                return (
                                    <AccessComponent app={a.application} access={a} reset={reset}/>
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            }
        </React.Fragment>
    )
}

interface AccessComponentProps {
    app: string
    access: any
    reset: string
}


// AccessComponent displays all the information concerning user access for a single application
const AccessComponent: React.FC<AccessComponentProps> = (props: AccessComponentProps) => {
    const [expand, setExpand] = useState<boolean>(false);


    useEffect(() => {
        setExpand(false)
    }, [props.reset]);

    const unfold = (app: string) => {
        setExpand(!expand)
    }


    const disableAccessClient = (app: string, client: string) => {
        requestMap.disableAccessClient(app, client)
    }

    const enableAccessClient = (app: string, client: string) => {
        requestMap.enableAccessClient(app, client)
    }




    return (
        //        <div key={props.app} className={"accessElement"} onClick={() => unfold(props.app)}>
        <div key={props.app} className={"accessElement"}>


            <Row>
                <Col lg={11} style={{paddingLeft: "10px"}}>
                    <>  </>
                    {props.app.includes("AWS") === true ?
                        <img width="30" height="30" src={require("../../images/my-access-aws.png")} alt='myAccessAWS'/> : <></>}
                    <>  </>
                    {props.app.includes("Patronus") === true ?
                        <img width="65" height="35" src={require("../../images/patronus-logo.png")}
                             alt='myAccessPatronus'/> : <></>}
                    <>  </>
                    {props.app}
                    </Col>

                <Col >
                    { !expand ?
                        <Button variant="warning" onClick={() => unfold(props.app)}>
                            &gt;
                        </Button>
                            :
                        <Button variant="warning" onClick={() => unfold(props.app)}>
                            v
                        </Button>
                    }
                </Col>
                </Row>


            <LineComponent hidden={!expand}/>
            <div hidden={!expand} style={{color: "#666"}}>
                {props.access.clients.map((client: any) => {

                    return (
                        <div hidden={!expand} style={{color: "#666"}}>
                            <Row>
                                <Col sm={1}>

                                {client["client_id"].includes("urn:amazon:webservices") === true ?
                                <img style={{float: "left"}} width="80" height="28"
                                     src={require("../../images/my-access-nngroup-auth.png")} alt='myAccessNNauth'/> :
                                <img style={{float: "left"}} width="80" height="28"
                                     src={require("../../images/my-access-patronus-auth.png")}
                                     alt='myAccessPatronusauth'/>}
                            </Col>

                            <Col style={{content: "center"}}>
                            <b>{client["client"]}</b>
                            <>  </>
                            {client["break_glass_procedure_client"] === true || client["client"].includes("RPS K8S BGP FLOW") ?
                                <img width="33" height="18" src={require("../../images/my-access-bgp.png")}
                                     alt='myAccessBGP'/> : <></>}
                            <>  </>
                            {client["production_workload"] === true ?
                                <img width="33" height="18" src={require("../../images/my-access-production.png")}
                                     alt='myAccessProd'/> : <></>}

                            <br/>


                        {client["break_glass_procedure_client"] === true ?
                            // BGP client below
                            <>
                                {client["application_urls"] !== undefined ? (client["application_urls"] as string[]).map((appUrl: string) => {
                                // Displays a hint for an url (mostly SAML request clients)
                                if (appUrl.includes("nn-auth")) {
                                    return [
                                        <i>
                                            <br></br>
                                            {/*nn-auth command/parameters for version >= 4.31*/}
                                            <>  </>
                                            <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.replace("--no-proxy ", ""))}>
                                                <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.replace("--no-proxy ", ""))}
                                                     width="18" height="18" src={require("../../images/copy.png")}
                                                     alt='copy-nn-auth'/>
                                                Copy nn-auth command (version &gt;= 4.3.1)
                                            </Button>
                                            <>  </>
                                            <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length).replace("--no-proxy ", ""))}>
                                                <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length).replace("--no-proxy ", ""))}
                                                     width="18" height="18" src={require("../../images/copy.png")}
                                                     alt='copy-nn-auth'/>
                                                Copy nn-auth command parameters (version &gt;= 4.3.1)
                                            </Button>

                                            <br></br>
                                            {/*nn-auth command/parameters for version < 4.3.1*/}
                                            <>  </>
                                            <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl)}>
                                                <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl)}
                                                     width="18" height="18" src={require("../../images/copy.png")}
                                                     alt='copy-nn-auth'/>
                                                Copy nn-auth command (version &lt; 4.3.1)
                                            </Button>
                                            <>  </>
                                            <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}>
                                                <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}
                                                     width="18" height="18" src={require("../../images/copy.png")}
                                                     alt='copy-nn-auth'/>
                                                Copy nn-auth command parameters (version &lt; 4.3.1)
                                            </Button>
                                        </i>]
                                } else {
                                    return [<><i>{<a href={appUrl} target="_blank" rel="noopener noreferrer">
                                        <Button className={"userButton"} data-tip data-for="refresh"
                                                variant="outline-secondary" type="submit">
                                            <img className={"copy-image"}
                                                 onClick={(event: any) => copyValue(event, appUrl)}
                                                 width="18" height="18" src={require("../../images/link.png")}
                                                 alt='copy-nn-auth'/>
                                            Start BGP session in a new tab
                                        </Button>
                                    </a>}</i>
                                        <>  </>
                                        <i>{
                                        <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}>
                                            <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}
                                                 width="18" height="18" src={require("../../images/copy.png")}
                                                 alt='copy-nn-auth'/>
                                            Copy url
                                        </Button>
                                    }</i></>
                                    ]
                                }
                            }) : <></>}
                            </>
                            // end BGP client below

                            :

                            <>
                                {client["client"].includes(":") === true ?
                                    // unsolicited SAML client below
                                    <>
                                        {client["application_urls"] !== undefined ? (client["application_urls"] as string[]).map((appUrl: string) => {
                                            // Displays a hint for an url (mostly SAML request clients)
                                            if (appUrl.includes("nn-auth")) {
                                                return [
                                                    <i>
                                                        <br></br>
                                                        {/*nn-auth command/parameters for version >= 4.31*/}
                                                        <>  </>
                                                        <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.replace("--no-proxy ", ""))}>
                                                            <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.replace("--no-proxy ", ""))}
                                                                 width="18" height="18" src={require("../../images/copy.png")}
                                                                 alt='copy-nn-auth'/>
                                                            Copy nn-auth command (version &gt;= 4.3.1)
                                                        </Button>
                                                        <>  </>
                                                        <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length).replace("--no-proxy ", ""))}>
                                                            <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length).replace("--no-proxy ", ""))}
                                                                 width="18" height="18" src={require("../../images/copy.png")}
                                                                 alt='copy-nn-auth'/>
                                                            Copy nn-auth command parameters (version &gt;= 4.3.1)
                                                        </Button>

                                                        <br></br>
                                                        {/*nn-auth command/parameters for version < 4.3.1*/}
                                                        <>    </>
                                                        <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl)}>
                                                            <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl)}
                                                                 width="20" height="15" src={require('../../images/copy.png')}
                                                                 alt='copy-nn-auth'/>
                                                            Copy nn-auth command (version &lt; 4.3.1)
                                                        </Button>
                                                        <>  </>
                                                        <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}>
                                                            <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}
                                                                 width="20" height="15" src={require("../../images/copy.png")}
                                                                 alt='copy-nn-auth'/>
                                                            Copy nn-auth command parameters (version &lt; 4.3.1)
                                                        </Button>

                                                    </i>]
                                            } else {
                                                return [<><i>{
                                                    <a href={appUrl} target="_blank" rel="noopener noreferrer">
                                                        <Button className={"userButton"} data-tip data-for="refresh" variant="outline-secondary"  type="submit">
                                                            <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl)}
                                                                 width="20" height="15" src={require("../../images/link.png")}
                                                                 alt='copy-nn-auth'/>
                                                            Start session in a new tab
                                                        </Button>
                                                    </a>
                                                }</i>
                                                    <>  </>
                                                    <i>{
                                                        <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}>
                                                            <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}
                                                                 width="18" height="18" src={require("../../images/copy.png")}
                                                                 alt='copy-nn-auth'/>
                                                            Copy url
                                                        </Button>
                                                    }</i></>
                                                ]
                                            }
                                        }) : <></>}
                                    </>
                                    // end unsolicited SAML client below

                                    :

                                    <>
                                        {client["application_urls"].length > 1 ?
                                            // client with multiple links
                                            <>
                                                {client["application_urls"] !== undefined ? (client["application_urls"] as string[]).map((appUrl: string) => {

                                                    return [<><i>{
                                                        <a href={appUrl} target="_blank" rel="noopener noreferrer">
                                                            <Button className={"userButton"} data-tip data-for="refresh" variant="outline-secondary"  type="submit">
                                                                <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl)}
                                                                     width="20" height="15" src={require("../../images/link.png")}
                                                                     alt='copy-nn-auth'/>
                                                                Open in a new tab -&gt; {appUrl}
                                                            </Button>

                                                        </a>
                                                    }</i>
                                                        <>  </>
                                                        <i>{
                                                            <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}>
                                                                <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}
                                                                     width="18" height="18" src={require("../../images/copy.png")}
                                                                     alt='copy-nn-auth'/>
                                                                Copy url -&gt; {appUrl}
                                                            </Button>
                                                        }</i>
                                                        <br/>
                                                    </>
                                                    ]
                                                }) : <></>}
                                            </>
                                            // end client with multiple links

                                            :

                                            <>
                                                {client["application_urls"] !== undefined ? (client["application_urls"] as string[]).map((appUrl: string) => {
                                                    // regular processing for clients with one url

                                                    // Displays a hint for an url (mostly SAML request clients)
                                                    if (appUrl.includes("Hint")) {
                                                        return [<i>{appUrl}</i>,
                                                            <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl)}
                                                                 width="20" height="15" src={require('../../images/copy.png')}
                                                                 alt='copy-nn-auth'/>
                                                        ]
                                                    } else {
                                                        // Display app url (single)
                                                        return [
                                                            // <><img style={{float: "left"}} width="80" height="28"
                                                            //        src={require("../../images/my-access-blank.png")}
                                                            //        alt='myAccessblank'/></>,
                                                            <><i>{
                                                            <a href={appUrl} target="_blank" rel="noopener noreferrer">
                                                                <Button className={"userButton"} data-tip data-for="refresh" variant="outline-secondary"  type="submit">
                                                                    <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl)}
                                                                         width="20" height="15" src={require("../../images/link.png")}
                                                                         alt='copy-nn-auth'/>
                                                                    Open in a new tab
                                                                </Button>
                                                            </a>
                                                        }</i>
                                                            <>  </>
                                                            <i>{
                                                                <Button data-tip data-for="copyValue" variant="outline-secondary" onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}>
                                                                    <img className={"copy-image"} onClick={(event: any) => copyValue(event, appUrl.substring(appUrl.indexOf(' '), appUrl.length))}
                                                                         width="18" height="18" src={require("../../images/copy.png")}
                                                                         alt='copy-nn-auth'/>
                                                                    Copy url
                                                                </Button>
                                                            }</i>
                                                            </>


                                                        ]
                                                    }
                                                }) : <></>}
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                            <hr></hr>
                            </Col>

                            <Col sm={1}>
                                {
                                    <ManageAccessButton client={client}/>
                                }
                            </Col>

                            </Row>
                        </div>
                                )
                })}
            </div>
        </div>
                    )
}

interface ManageAccessButtonComponentProps {
    client: any
}

const ManageAccessButton: React.FC<ManageAccessButtonComponentProps> = (props: ManageAccessButtonComponentProps) => {
    const [afterDisable, setAfterDisable] = useState<boolean>(false);
    const [afterEnable, setAfterEnable] = useState<boolean>(false);


    const disableAccessClient = (app: string, client: string) => {
        requestMap.disableAccessClient(app, client)
        setAfterDisable(true)
        setAfterEnable(false)
    }

    const enableAccessClient = (app: string, client: string) => {
        requestMap.enableAccessClient(app, client)
        setAfterEnable(true)
        setAfterDisable(false)
    }

    return (
        afterDisable ?
            <>
                <Button size="sm" variant="outline-dark" id={props.client["application"]+"|"+props.client["client"]} onClick={() => enableAccessClient(props.client["application"], props.client["client"])}>
                    <img className={"access-visible"} width="20" height="20" src={require("../../images/my-access-visible.png")} alt='enable-access'/>
                </Button>
            </>
            : afterEnable ?
                <>
                    <Button size="sm" variant="outline-dark" id={props.client["application"]+"|"+props.client["client"]} onClick={() => disableAccessClient(props.client["application"], props.client["client"])}>
                        <img className={"access-hidden"} width="20" height="20" src={require("../../images/my-access-hidden.png")} alt='disable-access'/>
                    </Button>
                </>
                :
                props.client["visible"] ?
            <>
            <Button size="sm" variant="outline-dark" id={props.client["application"]+"|"+props.client["client"]} onClick={() => disableAccessClient(props.client["application"], props.client["client"])}>
                <img className={"access-hidden"} width="20" height="20" src={require("../../images/my-access-hidden.png")} alt='disable-access'/>
            </Button>
            </>
            :
            <>
            <Button size="sm" variant="outline-dark" id={props.client["application"]+"|"+props.client["client"]} onClick={() => enableAccessClient(props.client["application"], props.client["client"])}>
                <img className={"access-visible"} width="20" height="20" src={require("../../images/my-access-visible.png")} alt='enable-access'/>
            </Button>
        </>
    )
}


export const copyValue = async(event: any, value: string | undefined) => {
    event.stopPropagation();
    if (value === undefined) {
        return
    }
    await navigator.clipboard.writeText(value)
};
