import React from 'react'
import {Col, Form, Row} from 'react-bootstrap'

export interface StringInputProps {
    className?: string
    placeholder?: string
    hidden?: boolean
    controlId?: string
    disabled?: boolean
    label?: string
    value: string | undefined
    tooltipHidden?: boolean
    tooltipMessage?: string | undefined
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    style?: any
    labelLength?: number
    onBlur?: (value: any) => boolean
}

export const StringInput: React.FC<StringInputProps> = (props: StringInputProps) => {
    return (
        <Form.Group hidden={props.hidden} as={Row} controlId={props.controlId}>
            {props.label ?
                <Form.Label column sm={props.labelLength ? props.labelLength : 2}>{props.label}</Form.Label> : <></>}

            <Col>
                <Form.Control placeholder={props.placeholder} className={props.className}
                              style={props.style}
                              disabled={props.disabled}
                              type={"input"}
                              value={props.value}
                              onChange={props.handleChange}/>
            </Col>
        </Form.Group>
    )
};
