import React from "react";

import './UserInfo.scss';
import {Button} from "react-bootstrap";
import configReader from "../../shared/helpers/config";
import {requestMap} from "../../shared/service/request/requestMap";
import { useEffect, useState } from "react";

const config = configReader();

const reLogin = async () => {
    window.location.replace(`https://`+ (`${config.domainExtension}` === "net" ? "test" : "")+`myaccess.patronus.nnip.${config.domainExtension}`);
};


const UserInfo: React.FC = () => {
    const [email, setEmail] = useState("");

    const userEmail = async () => {
        const userEmail = await requestMap.getRequest("myaccess/data", {"attribute": "email"});
        setEmail(userEmail["email"])
    };

    useEffect(() => {
        userEmail()
    }, [])


    return (
        <>
            <div className={"userInfo"}>
                <h1 className={"title"} style={{color: "#007BFF", margin: "10px", float:"left"}}>My Access</h1>
                {email}
                <>   </>
                <Button className={"userButton"} data-tip data-for="refresh" variant="outline-secondary" onClick={reLogin} type="submit">
                    <img onClick={reLogin} width="30" height="30" src={require("../../images/my-access-refresh.png")} alt='refresh'/>
                </Button>
            </div>
        </>
    );
};

export default UserInfo;