import React from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import './toggle-input-style.css';
import Toggle from "react-toggle";

export interface ToggleInputProps {
    hidden?: boolean
    id: string
    label?: string
    value: boolean
    disabled?: boolean
    labelLength?: number
    handleChange: any
}

export const ToggleInput: React.FC<ToggleInputProps> = (props: ToggleInputProps) => {
    return (
        <Form.Group hidden={props.hidden} as={Row} controlId={props.id}>

            <Form.Label column sm={props.labelLength ? props.labelLength : 2}>
                <label htmlFor={props.id} className={"toggle-label"}>{props.label}</label>
                <>                                                          </>
            </Form.Label>
            <Col sm={2}>
                <div style={{padding: '10px'}}>
                    <Toggle
                        id={props.id}
                        defaultChecked={props.value}
                        onChange={props.handleChange}
                        disabled={props.disabled}/>
                </div>
            </Col>
        </Form.Group>
    )
};
