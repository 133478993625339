
export const GET = 'GET';
export const POST = 'POST';

export enum requestMethods {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    PUT = 'PUT',
}

export function Request(method: requestMethods,
                        actionType: string,
                        url: string,
                        body: any = null,
                        options = {}): Promise<any> {
    return fetch(url, {
        method,
        ..._hasBody(body),
        ...options,
    })
        .then(async response => {
            const parsedResponse = _getParsedResponse(await response.text());
            // switch (response.status) {
            //     case 403:
            //         _showUnauthorizedModal();
            //         break
            //     case 501:
            //         _showNotImplementedModal();
            //         break
            //     case 200:
            //         _dispatchSuccessAction(actionType, parsedResponse)
            //         break
            // }
            return parsedResponse
        })
        .catch((errorResponse) => {
            // _showErrorModal();
            console.error(errorResponse)
        })
}

function _getParsedResponse(response: string): {} {
    try {
        return response ? JSON.parse(response) : {}
    } catch (e) {
        return {}
    }
}

function _hasBody(requestBody: any): {} | null {
    if (requestBody) {
        return {
            body: JSON.stringify(requestBody)
        };
    }

    return null
}
